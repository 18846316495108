import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlogLIstBlockDefault.module.css';
import SubscribeForm from '../../../../components/SubscribeForm/SubscribeForm';
import { TRUE } from '../../SectionBuilder/BlogPostPage/BlogPostArticle/BlogPostArticle';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    customFunc,
    isSubscribeForm,
    sectionId,
  } = props;
  const classes = classNames(rootClassName || css.root, className,blockId == "button" && css.buttonBlock);
  const hasTextComponentFields = hasDataInFields(
    [title, text, callToAction],
    options
  );


  return (
    <BlockContainer id={blockId} className={classes}>
      <div className={classNames((sectionId == "how-to-lend1" || sectionId == "looking_artical") && css.lendSectionMedia, (sectionId == "how_to_lend" || sectionId == "how_to_rent") && css.howItWorksPageLend, sectionId == "why_rent" && css.whyRentMedia, sectionId == "hear_it" && css.hear_itMedia, blockId !== "button" && sectionId == "how-to-lend" && css.howtoLendMedia, css.blockMediaWrapper)}>
        <FieldMedia
          media={media}
          sizes={sectionId == "hero" ? `(max-width: 767px) 100vw, 1800px` : responsiveImageSizes}
          className={mediaClassName}
          options={options}
        />
      </div>
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text, (sectionId == "why_lend" || sectionId == "hear_it") && css.why_lendMedia, (sectionId == "how_to_rent" || sectionId == "why_rent") && css.boldText,((sectionId == "how-to-lend") || (sectionId == "looking_artical")) && css.blockTitle)}>

          {sectionId == "hero" ? blockId == "img1" ? <p>want to take your 'fit from day to night? <br /> we've got you covered! <br /> rent the edit now</p> : blockId == "img2" ? <p>looking for a pre-loved piece to take home with you?</p> : blockId == "img3" ? <p>looking to start a side hustle with your wardrobe?</p> : null : null}
          <Field data={title} options={options} className={classNames((sectionId == "how-to-lend") ||(sectionId == "how-to-lend") && css.blockTitle )} />
          <Field data={text} options={options} />
          {blockId == "button" ? <div className={css.buttonCenterText}> <Field
            data={callToAction}
            className={ctaButtonClass}
            options={options}
          />
          </div> : <Field
            data={callToAction}
            className={ctaButtonClass}
            options={options}
          />}

          {isSubscribeForm === TRUE ? <SubscribeForm /> : null}
          {/* <button>ghghg</button> */}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;