import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionArticle.module.css';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { createSlug } from '../../../../util/urlHelpers';
import SubscribeForm from '../../../../components/SubscribeForm/SubscribeForm';
import { IconArrowHead, ListSlider, ListingCard } from '../../../../components';
import Slider from "react-slick";
import ListingCarousel from '../../../../components/ListingCard/ListingCarousel';
import { FormattedMessage } from 'react-intl';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <IconArrowHead direction="right" size="big" />
  </div>);
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <IconArrowHead direction="left" size="big" />

  </div >);
}


const SectionArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    currentUser,
  } = props;
  const twelve = options?.listings.slice(0, 12);
 
  const [insta, setInsta] = useState([])

  const additionalData = options && options.additionalData;
  const [blog, setBlogs] = useState(false);
  const history = useHistory();
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields(
    [title, description, callToAction],
    fieldOptions
  );
  const hasBlocks = blocks?.length > 0;
  const routes = routeConfiguration();

  const lb =
    typeof window !== 'undefined' &&
    typeof window.localStorage.getItem('localBookmarks') === 'string' &&
    JSON.parse(window.localStorage.getItem('localBookmarks'));

  const handleWishlist = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isAuthenticated && e && id) {
      // const routes = routeConfiguration();
      // history.push(createResourceLocatorString('LoginPage', routes, {}, {}));

      let localBookmarks =
        typeof window !== 'undefined' &&
          window.localStorage.getItem('localBookmarks') &&
          window.localStorage.getItem('localBookmarks').length > 0
          ? window.localStorage.getItem('localBookmarks')
          : [];

      if (typeof localBookmarks === 'string') {
        localBookmarks =
          typeof window !== 'undefined' &&
          JSON.parse(window.localStorage.getItem('localBookmarks'));
      }

      const localIndex =
        localBookmarks && localBookmarks.findIndex(b => b == id);

      if (localIndex > -1) {
        localBookmarks && localBookmarks.splice(localIndex, 1);
        const removedBookmarks = Array.from(new Set(localBookmarks));
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'localBookmarks',
            JSON.stringify(removedBookmarks)
          );
      } else {
        localBookmarks.push(id);
        const addedBookmarks = Array.from(new Set(localBookmarks));
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'localBookmarks',
            JSON.stringify(addedBookmarks)
          );
      }
    }
    const index = bookmarks && bookmarks.findIndex(b => b == id);

    if (id) {
      if (index > -1) {
        bookmarks && bookmarks.splice(index, 1);
        const removedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: removedBookmarks,
          },
        };
        onUpdateProfile(profile);
      } else {
        bookmarks.push(id);
        const addedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: addedBookmarks,
          },
        };
        onUpdateProfile(profile);
      }
    }
  };

  const bookmarks =
    currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks
      ? currentUser.attributes.profile.publicData.bookmarks
      : [];

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1722,
        settings: {
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName, sectionId == 'keep-up' && css.articleMainsocial)}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field
            data={title}
            className={
              sectionId == "pricing-delivery-tips" ? css.deliveryHeading :
                defaultClasses.title}
            options={fieldOptions}
          />
          <Field
            data={description}
            className={defaultClasses.description}
            options={fieldOptions}
          />
          <Field
            data={callToAction}
            className={defaultClasses.ctaButton}
            options={fieldOptions}
          />
          {/* {sectionId === 'summer-trends' &&
            additionalData &&
            additionalData.length &&
            options.listings &&
            options.listings.length ? (
            <div
              className={classNames(
                css.commonSection,
                css.recentlyAddedSection
              )}
            >
              {additionalData.map(e => {
                const index = options.listings.findIndex(
                  st =>
                    st?.attributes?.publicData?.label == e?.attributes?.label
                );
               
                if (index == 0) {
                  return (
                    <div className={css.fixWidthContainer}>
                      <div className={css.trendingTitle}>
                        {e.attributes.labelTitle}
                      </div>
                      <ListSlider
                        title={e?.attributes?.labelTitle}
                        viewport={options?.viewport}
                        extendedData={'amenities'}
                        category={'negocios'}
                        listings={options?.listings.filter(
                          st =>
                            st.attributes.publicData.label == e.attributes.label
                        )}
                        queryParams={{}}
                        intl={options?.intl}
                        sliderHeading={css.sliderHeading}
                        isLandingPage={true}
                        homeMobileSlider={css.homeMobileSlider}
                        isPopular={true}
                        bookmarks={lb || bookmarks}
                        isAuthenticated={options?.isAuthenticated}
                        handleWishlist={handleWishlist}
                        newIn={true}
                      />
                    </div>
                  );
                }
              })}
            </div>
          ) : null} */}

          {/* {sectionId === 'summer-trends' &&
            additionalData &&
            additionalData.length &&
            options.listings &&
            options.listings.length ? (
            <div
              className={classNames(
                css.commonSection,
                css.recentlyAddedSection
              )}
            >
              {additionalData.map(e => {
                const index = options?.listings.findIndex(
                  st =>
                    st?.attributes?.publicData?.label == e?.attributes?.label
                );
                // console.log(options.listings.filter(
                //   st =>
                //     st?.attributes?.publicData?.label == e?.attributes?.label
                // ))
                // console.log(index,"index");
                if (index >= 1) {
                  return (
                    <div className={css.fixWidthContainer}>
                      <div className={css.trendingTitle}>
                        {e.attributes.labelTitle}
                      </div>
                      <ListSlider
                        title={e.attributes.labelTitle}
                        viewport={options?.viewport}
                        extendedData={'amenities'}
                        category={'negocios'}
                        listings={options?.listings.filter(
                          st =>
                            st.attributes.publicData.label == e.attributes.label
                        )}
                        queryParams={{}}
                        intl={options?.intl}
                        sliderHeading={css.sliderHeading}
                        isLandingPage={true}
                        homeMobileSlider={css.homeMobileSlider}
                        isPopular={true}
                        bookmarks={lb || bookmarks}
                        isAuthenticated={options?.isAuthenticated}
                        handleWishlist={handleWishlist}
                      />
                    </div>
                  );
                }
              })}
            </div>
          ) : null} */}

        </header>
      ) : null
      }

      {sectionId === 'summer-trends' &&
        additionalData &&
        additionalData.length &&
        options.listings &&
        options.listings.length ? (
        <div
          className={classNames(
            css.commonSection,
            css.recentlyAddedSection
          )}
        >

        </div>
      ) : null}
      {sectionId === 'new-in' &&
        additionalData &&
        additionalData.length &&
        options.listings &&
        options.listings.length ? (
        <div
          className={classNames(
            css.commonSection,
            css.recentlyAddedSection
          )}
        >

          {additionalData.map(e => {
            const index = options.listings.findIndex(
              st =>
                st?.attributes?.publicData?.label == e?.attributes?.label
            );
            if (index == 0) {
              return (
                <div className={css.fixWidthContainer}>
                  <ListSlider
                    title={e.attributes.labelTitle}
                    viewport={options?.viewport}
                    extendedData={'amenities'}
                    category={'negocios'}
                    listings={options.listings}
                    queryParams={{}}
                    intl={options?.intl}
                    sliderHeading={css.sliderHeading}
                    isLandingPage={true}
                    homeMobileSlider={css.homeMobileSlider}
                    isPopular={true}
                    bookmarks={lb || bookmarks}
                    isAuthenticated={options?.isAuthenticated}
                    handleWishlist={handleWishlist}
                    newIn={true}
                  />
                </div>
              );
            }
          })}
        </div>
      ) : null}

      {sectionId == 'summer-trends' ?
      <div className={css.sliderWrapper}>
       <Slider {...settings}>
        {twelve.map(listing => (

          <ListingCarousel
            key={listing.id.uuid}
            listing={listing}
            intl={options.intl}
            showLiveBanner={true}
            isLandingPage={true}
            profileSliderCardTitle={props.profileSliderCardTitle}
            profileSliderCardauthorInfo={props.profileSliderCardauthorInfo}
            profileSliderCardprice={props.profileSliderCardprice}
            // homeMobileSlider={isMobileLayout ? props.homeMobileSlider : ' '}
            bookmarks={bookmarks}
            isAuthenticated={options.isAuthenticated}
            handleWishlist={handleWishlist}
            className={css.listingCard}
          />

        ))}
      </Slider></div>  : null}
      {sectionId==="keep-up" ? <div className={css.insta}><FormattedMessage id="SectionArticle.insta" /></div>:null}
      {
        hasBlocks ? (
          <div
            className={classNames(
              defaultClasses.blockContainer,
              css.articleMain,
              {
                [css.noSidePaddings]: isInsideContainer,
              }
            )}
          >
            <BlockBuilder
              className={sectionId == "how-it-works" ? css.howItWorkSection :
                sectionId == "what_to_upload" ? css.howItWorkSection :
                  sectionId == "profile-tips" ? css.howItWorkSection :
                    sectionId == "pricing-delivery-tips" ? css.howItWorkSection :
                      null}
              blocks={blocks}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
              SubscribeForm={SubscribeForm}
            />
          </div>
        ) : null
      }
    </SectionContainer >
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticle;
