import React, { Component, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney, formattedMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  Avatar,
} from '../../components';

import css from './ListingCard.module.css';
import IconStar from '../../containers/SearchPage/IconStar/IconStar';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formattedMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCarouselComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    bookmarks,
    handleWishlist,
    isAuthenticated,
    profileWishlist,
    pagename
  } = props;

  const classes = classNames(rootClassName || css.root, className,css.carouselCard);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;

  const { type, label } = currentListing && currentListing.attributes && currentListing.attributes.publicData;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  let firstImage =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k =>
      k.startsWith(variantPrefix)
    )
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = type == 'For Sale' ? config.lineItemSaleUnitType : config.lineItemRentUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

//   const unitTranslationKey = isNightly
//     ? 'ListingCard.perNight'
//     : isDaily
//       ? 'ListingCard.perDay'
//       : 'ListingCard.perUnit';

  const [isHovered, setHovered] = useState('');
  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  if (
    isHovered &&
    currentListing &&
    currentListing.images &&
    currentListing.images.length > 1
  ) {
    firstImage = currentListing.images[1];
  }
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.tagImageWrapper}>

      <span className={css.tagName}>
        <span className={type == 'For Sale' ? css.cardTypeSale : type == 'For Sale/Rent' ? css.cardTypeCharity : type == 'For Rent' ? css.cardTypeRent : null}>{type == "For Sale/Rent" ? "For Rent/Sale" : type}</span>
      </span>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
        onMouseOver={() => setHovered('hover')}
        onMouseOut={() => setHovered('')}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      </div>

      <div className={css.info}>
        <div>
          <span className={css.brandName}>{publicData?.brand}</span>
          <div className={css.sizeInformation}>
            {publicData?.sizeOptions.length && publicData?.sizeOptions.map((item,i) => {
              return (
                <span key={item+i}>{item}</span>
              )
            })}
          </div>
          {/* <div className={css.hr}></div> */}
          <div className={pagename === "ProfilePage" ? css.priceStraight : css.priceSec}>
            <div className={css.price}>
              <div className={classNames(css.priceValue,css.priceValueBorder)} title={priceTitle}>
          {type == 'For Sale/Rent' ? <><div>RENT FROM €{Math.round(price?.amount / 100)} </div><div>RRP €{Math.round(publicData?.ReailPrice )}</div></> : null}
                {type == 'For Rent' ? <><div>RENT FROM €{Math.round(price?.amount / 100)} </div><div>RRP €{Math.round(publicData?.ReailPrice )}</div></> : type == 'For Sale' ? <><div>BUY NOW €{Math.round(price?.amount / 100)} </div><div>RRP €{Math.round(publicData?.salePrice / 100)}</div></> : null}
              </div>
              {/* {
                type == 'For Sale/Rent' ? null :
                  <div className={css.perUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                  </div>
              } */}
            </div>
          </div>
          {/* <div className={css.categoryBox}>
            <div className={css.categoryName}>{publicData?.category}</div>
            {label ?
              <span className={css.tagTrending}>
                <span>{label}</span>
              </span> : null
            }
          </div> */}
        </div>

        {/* <div className={css.title}>
          {richText(
            title && title.length > 21 ? `${title.slice(0, 22)}...` : title,
            {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            }
          )}
        </div>
        <div className={css.title}>
          {publicData?.brand}
        </div> */}

        {/* {pagename === "ProfilePage" ? null :
          <div className={css.mainInfo}>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <span className={css.authorImg}>
                  <Avatar user={author} className={css.avatar} />
                </span>
                <FormattedMessage
                  id="ListingCard.author"
                  values={{ authorName }}
                />
              </div>
            ) : null}
            <div
              className={css.favorite}
            >
              {
                bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                  <span
                    className={css.filled}
                    onClick={e => {
                      handleWishlist(id, e);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 24L10.32 22.1739C4.08 16.1739 0 12.1304 0 7.17391C0 3.13043 2.88 0 6.6 0C8.64 0 10.68 1.04348 12 2.73913C13.3176 1.04348 15.36 0 17.4 0C21.12 0 24 3.13043 24 7.17391C24 12.1304 19.92 16.1739 13.68 22.1739L12 24Z"
                        fill="#F887DC"
                      />
                    </svg>
                  </span>
                ) : (
                  <span
                    className={css.bookmark}
                    onClick={e => {
                      handleWishlist(id, e);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 24L10.32 22.1739C4.08 16.1739 0 12.1304 0 7.17391C0 3.13043 2.88 0 6.6 0C8.64 0 10.68 1.04348 12 2.73913C13.3176 1.04348 15.36 0 17.4 0C21.12 0 24 3.13043 24 7.17391C24 12.1304 19.92 16.1739 13.68 22.1739L12 24Z"
                        fill="#f1f1f1"
                      />
                    </svg>
                  </span>
                )
              }
            </div>
          </div>} */}
      </div>
    </NamedLink>
  );
};

ListingCarouselComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCarouselComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCarouselComponent);
