import React, { useState } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../../Field';
import BlockBuilder from '../../../BlockBuilder';

import SectionContainer from '../../SectionContainer';
import css from './BlogPostArticle.module.css';
import SubscribeForm from '../../../../../components/SubscribeForm/SubscribeForm';
export const TRUE = 'true'
// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    sections,
  } = props;

  const [blog, setBlogs] = useState(false);
  const currSectionIndex = Array.isArray(sections) && sections.length && sections.findIndex(elm => elm.sectionId === sectionId);
  const nextSectionIndex = currSectionIndex > -1 && currSectionIndex + 1
  const prevSectionIndex = currSectionIndex > -1 && currSectionIndex !== 0 && currSectionIndex - 1;
  const nextSectionId = sections[nextSectionIndex];
  const prevSectionId = sections[prevSectionIndex];

 
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields(
    [title, description, callToAction],
    fieldOptions
  );
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field
            data={title}
            className={defaultClasses.title}
            options={fieldOptions}
          />
          <Field
            data={description}
            className={defaultClasses.description}
            options={fieldOptions}
          />
          <Field
            data={callToAction}
            className={defaultClasses.ctaButton}
            options={fieldOptions}
          />
          {/* <SubscribeForm></SubscribeForm> */}
        </header>
      ) : null}
      {hasBlocks ? (
        <div
          className={classNames(
            defaultClasses.blockContainer,
            css.articleMain,
            {
              [css.noSidePaddings]: isInsideContainer,
            }
          )}
        >
          <BlockBuilder
            blocks={blog ? [blocks[0]] : [blocks[1]]}
            ctaButtonClass={defaultClasses.ctaButton}
            options={options}
            isSubscribeForm={TRUE}
            sectionId={sectionId}
            nextSectionId={nextSectionId}
            prevSectionId={prevSectionId}
          />
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticle;
